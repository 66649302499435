body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Noto Sans', 'Roboto', 'Segoe UI',
    'Malgun Gothic', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* semantic-ui override */
.ui.header {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Noto Sans', 'Roboto', 'Segoe UI',
    'Malgun Gothic', sans-serif !important;
}
.ui.menu .active.item {
  font-weight: bold !important;
}
.ui.list .list > .item,
.ui.list > .item,
ol.ui.list li,
ul.ui.list li {
  line-height: 1.4285em !important;
  padding: 0 !important;
}

.ui.ordered.list .list,
ol.ui.list ol {
  padding-top: 0 !important;
}
p.text {
  font-size: 1.14285714rem !important;
}

/* customizing */
.field + .input-error {
  color: #9f3a38;
  margin-top: -0.5rem !important;
  font-size: 1rem !important;
}

.ui.header + .ui.list,
.ui.header + ol.ui.list,
.ui.header + ul.ui.list {
  margin-top: 0 !important;
}

.ui.basic.primary.default.button, .ui.basic.primary.default.buttons .button {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  font-weight: bold;
}

/* module */
.bg-whitesmoke {
  background: whitesmoke;
}

.bt-1 {
  border-top: 1px solid lightgray;
}

.bv-1 {
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
}
.fs-1 {
  font-size: 1rem !important;
}
.fs-3 {
  font-size: 3rem !important;
}
.fw-b {
  font-weight: bold !important;
}
.mt-0 {
  margin-top: 0rem !important;
}
.mt-q {
  margin-top: 0.25rem !important;
}
.mt-h {
  margin-top: 0.5rem !important;
}
.mt-1 {
  margin-top: 1rem !important;
}
.mt-2 {
  margin-top: 2rem !important;
}
.mt-3 {
  margin-top: 3rem !important;
}
.mt-4 {
  margin-top: 4rem !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mb-q {
  margin-bottom: 0.25rem !important;
}
.mb-h {
  margin-bottom: 0.5rem !important;
}
.mb-1 {
  margin-bottom: 1rem !important;
}
.mb-2 {
  margin-bottom: 2rem !important;
}
.mb-3 {
  margin-bottom: 3rem !important;
}
.mb-5 {
  margin-bottom: 5rem !important;
}
.mr-h {
  margin-right: 0.5rem !important;
}
.mr-1 {
  margin-right: 1rem !important;
}
.mh-20p {
  margin-left: 20% !important;
  margin-right: 20% !important;
}
.mv-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.mv-2 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}
.mv-3 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}
.mv-5 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.pb-3 {
  padding-bottom: 3rem !important;
}
.pl-0 {
  padding-left: 0 !important;
}
.pr-0 {
  padding-right: 0 !important;
}
.pr-1 {
  padding-right: 1rem !important;
}
.pv-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.pv-1 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
.pv-2 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}
.pv-3 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pv-5 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.ta-c {
  text-align: center;
}
.ta-l {
  text-align: left;
}
.ta-r {
  text-align: right;
}
.clear {
  clear: both;
}
.visible-xxs {
  display: none !important;
}
.hidden-xxs {
  display: block !important;
}
.fullscreen {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: calc(100% - 57px);
  position: relative;
}

.fullscreen-cover {
  /* background: rgba(0, 0, 0, 0.2); */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.fullscreen-textbox {
  background: rgba(0, 0, 0, 0.8);
  margin-top: 0;
  padding: 1.2rem 0;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 35%;
  -webkit-transform: translateY(-50%) translateX(-50%);
          transform: translateY(-50%) translateX(-50%);
}
.fullscreen-textbox .ui.header {
  font-size: 4rem;
  font-weight: 900;
}
.fullscreen-textbox .sub.header {
  font-size: 1.4rem !important;
  margin-top: 0.25rem !important;
  color: white !important;
}

.section-table {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: table !important;
  bottom: 0;
  width: 100%;
}

.section-tablecell {
  display: table-cell !important;
  padding: 18rem 0;
}

.section-table-sm .section-tablecell {
  padding: 9rem 0;
}

.section-header {
  font-size: 4rem !important;
  font-weight: 900 !important;
}

.section-sub-header {
  font-size: 1.5rem !important;
  font-weight: bold !important;
}
.darker {
  background: rgba(0, 0, 0, 0.3);
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, rgba(0, 0, 0, 0.3)),
    color-stop(100%, rgba(0, 0, 0, 0.3))
  );
  background: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.3)), to(rgba(0, 0, 0, 0.3)));
  background: linear-gradient(to right, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.3) 100%);
}

.small {
  font-size: 0.9rem !important;
}
.black {
  color: black !important;
}
.dimgray {
  color: dimgray !important;
}
.gray {
  color: gray !important;
}
.darkgray {
  color: darkgray !important;
}
.lightgray {
  color: lightgray !important;
}
.white {
  color: white !important;
}
.blue {
  color: #4183c4 !important;
}

.c-pointer {
  cursor: pointer !important;
}

/* special use */
/* 슬라이더 */
.slick-slider {
  background-color: whitesmoke;
}
.slick-prev {
  margin-left: 40px;
}
.slick-next {
  margin-right: 40px;
}
.slick-prev,
.slick-next {
  z-index: 1;
}
.slick-slide * {
  outline: none !important;
}

/* 이벤트 선택 */
.DayPickerInput,
.rc-time-picker {
  width: 100%;
}
.rc-time-picker-input {
  height: 2.7em !important;
}
.rc-time-picker-clear {
  top: 0.5rem !important;
}
.rc-time-picker-panel-inner,
.rc-time-picker-panel-select li {
  font-size: 1.1rem !important;
}

@media (max-width: 1199px) {
  .fullscreen-textbox {
    width: 50%;
  }
  .fullscreen-textbox .ui.header {
    font-size: 4rem !important;
  }
  .fullscreen-textbox .sub.header {
    font-size: 1.3rem !important;
  }
}

@media (max-width: 767px) {
  .section-tablecell {
    padding: 12rem 0 !important;
  }
  .section-table-sm .section-tablecell {
    padding: 6rem 0 !important;
  }
  .section-header {
    font-size: 3rem !important;
  }
  .section-sub-header {
    font-size: 1.3rem !important;
  }
  /* override */
  .ui.menu .item > i.dropdown.icon {
    display: none !important;
  }
  .ui.modal .content > .description {
    padding: 0 !important;
  }
  .ui.modal > .content.pv-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .fullscreen {
    height: calc(100% - 46px);
  }
  .fullscreen-textbox {
    width: 90%;
  }
  .fullscreen-textbox .ui.header {
    font-size: 3rem !important;
  }
  .fullscreen-textbox .sub.header {
    font-size: 1rem !important;
  }
}

@media (max-width: 480px) {
  .section-tablecell {
    padding: 8rem 0 !important;
  }
  .section-table-sm .section-tablecell {
    padding: 4rem 0 !important;
  }
  .section-header {
    font-size: 2rem !important;
  }

  .mv-3-xxs {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .visible-xxs {
    display: block !important;
  }
  .hidden-xxs {
    display: none !important;
  }
  .fullscreen-textbox {
    width: 90%;
  }
  .fullscreen-textbox .ui.header {
    font-size: 2.9rem !important;
  }
  .fullscreen-textbox .sub.header {
    font-size: 0.9rem !important;
  }
}
