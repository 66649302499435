.story-title.huge {
  /* font-size: 3rem !important; */
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.story-image {
  background: whitesmoke;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  width: 100%;
  padding-bottom: 50%;
  position: relative !important;
  overflow: hidden !important;
}
