/* override */
.rbc-calendar {
  display: block !important;
}
.rbc-month-view {
  height: 700px !important;
}
.rbc-month-view,
.rbc-agenda-content {
  background: white !important;
}
.rbc-toolbar-label {
  font-weight: bold;
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
  font-size: 1.5rem;
}
.rbc-header {
  padding: 0.25rem 0.75rem !important;
}
.rbc-event {
  background: transparent !important;
  color: dimgray !important;
  font-size: 0.85rem !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.rbc-row-segment {
  line-height: 1.1rem;
  padding: 1px 0 0 !important;
}
.rbc-agenda-table {
  text-align: left;
}
.rbc-show-more {
  text-align: left;
  padding-left: 2px;
  padding-right: 2px;
}
.rbc-overlay-header {
  font-weight: bold;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.rbc-calendar * {
  outline: none !important;
}

.calendar-container .ui.circular.empty.label {
  padding: 0.35em !important;
}

.rbc-calendar .rbc-month-view .rbc-month-row,
.rbc-calendar .rbc-month-view .rbc-month-row .rbc-row-content,
.rbc-calendar .rbc-month-view .rbc-month-row .rbc-row-content .rbc-date-cell {
  flex: 1;
}

@media (max-width: 767px) {
  .rbc-month-view {
    height: 500px !important;
  }
}

@media (max-width: 480px) {
  .rbc-month-view {
    height: 400px !important;
  }
  .rbc-btn-group {
    display: block;
    width: 100%;
  }
  .rbc-event-content {
    font-size: 0.7rem !important;
  }
  .rbc-overlay .rbc-event-content {
    font-size: 0.9rem !important;
  }
  .rbc-show-more {
    font-size: 0.5rem !important;
    font-weight: normal !important;
  }
  .rbc-header,
  .rbc-agenda-date-cell,
  .rbc-agenda-time-cell,
  .rbc-agenda-event-cell {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}

/* edge */
@supports (-ms-ime-align: auto) {
  .rbc-calendar {
    min-height: 600px;
  }
}

/* ie 9, 10, 11 */
@media screen and (min-width: 0\0) {
  .rbc-calendar {
    min-height: 600px;
  }
  .rbc-month-row {
    min-height: 100px;
  }
  .rbc-agenda-view {
    min-height: 600px;
  }
}
